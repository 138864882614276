import Vue from 'vue';
import Vuex from 'vuex';
import App from './App.vue';

import { library } from '@fortawesome/fontawesome-svg-core';
// TODO: Import required icons only
// import { fas } from '@fortawesome/free-solid-svg-icons';
import { fal } from '@fortawesome/pro-light-svg-icons';
import { far } from '@fortawesome/pro-regular-svg-icons';


import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

// Set up theming
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import locale from 'element-ui/lib/locale/lang/en';

import router from './router/';

Vue.config.productionTip = false;

Vue.use(Vuex);

Vue.use(ElementUI, { locale });

library.add(fal);
library.add(far);

Vue.component('font-awesome-icon', FontAwesomeIcon);

const store = new Vuex.Store({
  state: {
    languageTexts: {
      hu: 'Hungarian',
      en: 'English',
      de: 'German',
      ru: 'Russian',
      fr: 'French',
      it: 'Italian',
      nl: 'Dutch',
      hr: 'Croatian',
      sk: 'Slovak',
      pl: 'Polish',
      ro: 'Romanian',
      es: 'Spanish',
      el: 'Greek',
    },
    languages: ['hu', 'en', 'de', 'ru', 'fr', 'it'],
    sites: [],
    siteId: null,
  },
  actions: {
    storeSites (context, sites) {
      context.commit('STORE_SITES', sites);
    },
    selectSite (context, siteId) {
      context.commit('SELECT_SITE', siteId);
    },
  },
  getters: {},
  mutations: {
    STORE_SITES: (state, sites) => {
      state.sites = sites;
    },
    SELECT_SITE: (state, siteId) => {
      state.siteId = siteId;
    },
  },
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
