<template>
  <div>
    <h1>Products</h1>

    <template v-if="product">

      <div v-for="languageCode in languages" :key="languageCode">
        <h4>{{ languageText[languageCode] }}</h4>

        <div class="data-row">
          <el-row type="flex">
            <el-col class="label">Name</el-col>
            <el-col>
              <span>
                <el-input :disabled="saving" type="text" v-model="product.name[languageCode]"></el-input>
              </span>
            </el-col>
          </el-row>
        </div>
        <div class="data-row">
          <el-row type="flex">
            <el-col class="label">Description</el-col>
            <el-col>
              <div v-if="product.description">
                <el-input
                  type="textarea"
                  :autosize="{ minRows: 1, maxRows: 40 }"
                  :disabled="saving"
                  style="width: 100%;"
                  v-model="product.description[languageCode]"
                ></el-input>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>

      <h4>Properties</h4>

      <div class="data-row">
        <el-row type="flex">
          <el-col class="label">Category</el-col>
          <el-col>
            <el-select :disabled="saving" v-model="product.category">
              <el-option v-for="category in categories" :key="category" :value="category"></el-option>
              <el-option :value="null" label="None"></el-option>
            </el-select>
          </el-col>
        </el-row>
      </div>

      <div class="data-row">
        <el-row type="flex">
          <el-col class="label">Price (HUF)</el-col>
          <el-col>
            <el-input type="number" v-model="product.price"></el-input>
          </el-col>
        </el-row>
      </div>

      <el-button :disabled="saving" @click="updateProduct(product)">Save</el-button>
    </template>

  </div>
</template>

<script>

import { getProduct, listProducts, updateProduct } from '../../api/';
import * as uuid from 'uuid/v4';

export default {
  name: 'ProductFormComponent',
  props: [
    'productId',
  ],
  data() {
    return {
      saving: false,
      editing: 0,
      product: null,
      categories: [],
      defaultProps: {},

      languages: this.$store.state.languages,
      languageText: this.$store.state.languageTexts,
    };
  },

  mounted () {

    if (this.productId === 'new') {
      listProducts()
        .then((response) => response.data)
        .then((productList) => {
          this.product = {
            id: uuid(),
            name: {},
            description: {},
            price: 0,
            category: this.$route.query.category || null,
          };

          const categoryObj = productList
            .reduce((h, item) => {
              if (!item.category) {
                return h;
              }
              h[item.category] = true;
              return h;
            }, {});

          this.categories = Object.keys(categoryObj);
        });
      return;
    }

    Promise.all([
      listProducts().then((response) => response.data),
      getProduct(this.productId),
    ])
      .then(([productList, product]) => {
        this.product = product;

        const categoryObj = productList
          .reduce((h, item) => {
            if (!item.category) {
              return h;
            }
            h[item.category] = true;
            return h;
          }, {});

        this.categories = Object.keys(categoryObj);
      });
  },

  methods: {
    updateProduct (product) {
      this.saving = true;

      updateProduct(product.id, product)
        .then(() => {
          this.saving = false;

          this.$message({
            message: 'Product information updated successfully.',
            type: 'success'
          });

          if (this.productId === 'new') {
            this.$router.replace({ name: 'product', params: { productId: product.id }});
          }
        })
        .catch(() => {
          this.saving = false;

          this.$message({
            message: 'Product information update failed.',
            type: 'error'
          });
        });
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.label {
  width: 120px;
  text-align: right;
  padding-right: 10px;
}

.data-row {
  margin-bottom: 22px;
}
</style>
