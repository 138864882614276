import axios from 'axios';

import { getAccessToken } from '../utils/auth';

// const BASE_URL = 'http://localhost:8888/api/v1';
const BASE_URL = 'https://editor.visionadmin.com/api/v1';

export function getToken (username, password) {
  const url = `${BASE_URL}/login`;
  return axios
    .post(url, {
      username,
      password,
    })
    .then(response => response.data);
}

function getAuthHeader () {
  const token = getAccessToken();

  if (!token) {
    return {};
  }

  return {
    Authorization: `Bearer ${token}`,
  };
}

function getConfig () {
  const token = getAccessToken();

  if (!token) {
    return {};
  }

  return {
    headers: getAuthHeader(),
  };
}

export function listChannels () {
  const url = `${BASE_URL}/channels`;
  return axios.get(url, getConfig()).then(response => response.data);
}

export function getMenus () {
  const url = `${BASE_URL}/menus`;
  return axios.get(url, getConfig()).then(response => response.data);
}

export function getMenu (id) {
  const url = `${BASE_URL}/menus/${id}`;
  return axios.get(url, getConfig()).then(response => response.data);
}

export function patchMenu (id, data) {
  const url = `${BASE_URL}/menus/${id}`;
  return axios.patch(url, data, getConfig()).then(response => response.data);
}

export function getScreens () {
  const url = `${BASE_URL}/screens`;
  return axios.get(url, getConfig()).then(response => response.data);
}

export function getScreen (id) {
  const url = `${BASE_URL}/screens/${id}`;
  return axios.get(url, getConfig()).then(response => response.data);
}

export function patchScreen (id, data) {
  const url = `${BASE_URL}/screens/${id}`;
  return axios.patch(url, data, getConfig()).then(response => response.data);
}

export function getContent (id) {
  const url = `${BASE_URL}/contents/${id}`;
  return axios.get(url, getConfig()).then(response => response.data);
}

export function getContents () {
  const url = `${BASE_URL}/contents`;
  return axios.get(url, getConfig()).then(response => response.data);
}

export function updateContent (id, data) {
  const url = `${BASE_URL}/contents/${id}`;
  return axios.post(url, data, getConfig()).then(response => response.data);
}

export function updateProduct (id, data) {
  const url = `${BASE_URL}/products/${id}`;
  return axios.post(url, data, getConfig()).then(response => response.data);
}

export function listProducts () {
  const url = `${BASE_URL}/products`;
  return axios.get(url, getConfig()).then(response => response.data);
}

export function getProduct (id) {
  const url = `${BASE_URL}/products/${id}`;
  return axios.get(url, getConfig()).then(response => response.data);
}

export function deleteProduct (id) {
  const url = `${BASE_URL}/products/${id}`;
  return axios.delete(url, getConfig()).then(response => response.data);
}

export function listImages () {
  const url = `${BASE_URL}/images/`;
  return axios.get(url, getConfig()).then(response => response.data);
}

export function getImage (id) {
  const url = `${BASE_URL}/images/${id}`;
  return axios.get(url, getConfig()).then(response => response.data);
}

export function getImageRedirectUrl (id) {
  return `${BASE_URL}/images/${id}/redirect`;
}

export function listSites () {
  const url = `${BASE_URL}/sites/`;
  return axios.get(url, getConfig()).then(response => response.data);
}

export function getSite (id) {
  const url = `${BASE_URL}/sites/${id}`;
  return axios.get(url, getConfig()).then(response => response.data);
}

export function listBuilds () {
  const url = `${BASE_URL}/builds/`;
  return axios.get(url, getConfig()).then(response => response.data);
}

export function getBuild (id) {
  const url = `${BASE_URL}/builds/${id}`;
  return axios.get(url, getConfig()).then(response => response.data);
}

export function listTelevisions () {
  const url = `${BASE_URL}/televisions/`;
  return axios.get(url, getConfig()).then(response => response.data);
}

export function getTelevision (id) {
  const url = `${BASE_URL}/televisions/${id}`;
  return axios.get(url, getConfig()).then(response => response.data);
}

export function getConfigs () {
  const url = `${BASE_URL}/configs/`;
  return axios.get(url, getConfig()).then(response => response.data);
}

export function uploadGalleryImage (file) {
  const url = `${BASE_URL}`;

  const formData = new FormData();
  formData.append('file', file);

  return axios.post(`${url}/images:upload`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
      ...getAuthHeader(),
    },
  });
}
