<template>
  <div>
    <h1>Builds</h1>

    <el-table
      :data="tableData"
      stripe
      style="width: 100%">
      <el-table-column
        prop="id"
        label="ID"
        >
        <template slot-scope="scope">
          <!-- Route to preview application -->
          <!-- <router-link to="/"> -->
          <span>{{ scope.row.id }}</span>
          <!-- </router-link> -->
        </template>
      </el-table-column>
      <el-table-column
        prop="created"
        label="Created"
        >
      </el-table-column>
      <el-table-column
        prop="updated"
        label="Updated"
        >
      </el-table-column>
      <el-table-column
        label="Status"

        align="left">
        <template slot-scope="scope">
          <el-tag :type="scope.row.status" size="medium">{{ scope.row.text_status }}</el-tag>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>


import { listBuilds } from '../../api/';

export default {
  name: 'BuildsFormComponent',
  props: [
    'productId',
  ],
  data() {
    return {
      saving: false,
      editing: 0,
      product: null,
      categories: [],
      defaultProps: {},

      languages: this.$store.state.languages,
      languageText: this.$store.state.languageTexts,

      tableData: [],
    };
  },

  mounted () {

    const flagMap = {
      pending: 'info',
      running: 'info',
      succeeded: 'success',
      failed: 'danger',
    };

    const textMap = {
      pending: 'Pending',
      running: 'Running',
      succeeded: 'Succeeded',
      failed: 'Failed',
    };

    listBuilds()
      .then((response) => {

        this.tableData = response.data
          .map((build) => {
            return {
              id: build.id,
              date: build.created,
              created: build.created,
              updated: build.updated,
              status: flagMap[build.status],
              text_status: textMap[build.status],
            };
          });
      });
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* h1, h2 {
  font-weight: normal;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
} */
</style>
