import TelevisionsFormComponent from '@/components/overview/TelevisionsFormComponent';
import OverviewFormComponent from '@/components/overview/OverviewFormComponent';
import ContentsFormComponent from '@/components/overview/ContentsFormComponent';
import SettingsFormComponent from '@/components/overview/SettingsFormComponent';
import ChannelsFormComponent from '@/components/overview/ChannelsFormComponent';
import ProductsFormComponent from '@/components/overview/ProductsFormComponent';
import ProductFormComponent from '@/components/overview/ProductFormComponent';
import ContentFormComponent from '@/components/overview/ContentFormComponent';
import ScreensFormComponent from '@/components/overview/ScreensFormComponent';
import ScreenFormComponent from '@/components/overview/ScreenFormComponent';
import GalleryFormComponent from '@/components/overview/GalleryFormComponent'
import BuildsFormComponent from '@/components/overview/BuildsFormComponent';
import MenusFormComponent from '@/components/overview/MenusFormComponent';
import LoginFormComponent from '@/components/overview/LoginFormComponent';
import MenuFormComponent from '@/components/overview/MenuFormComponent';
import ShopFormComponent from '@/components/overview/ShopFormComponent';


import { requireAuth } from '../utils/auth';

export default {
  routes: [
    {
      path: '/login/',
      name: 'login',
      component: LoginFormComponent,
    },
    {
      path: '/overview/',
      name: 'overview',
      component: OverviewFormComponent,
      beforeEnter: requireAuth,
    },
    {
      path: '/',
      component: OverviewFormComponent,
      beforeEnter: requireAuth,
    },
    {
      path: '/builds',
      name: 'builds',
      component: BuildsFormComponent,
      beforeEnter: requireAuth,
    },
    {
      path: '/contents',
      name: 'contents',
      component: ContentsFormComponent,
      beforeEnter: requireAuth,
    },
    {
      path: '/contents/:contentId',
      name: 'content',
      props: true,
      component: ContentFormComponent,
      beforeEnter: requireAuth,
    },
    {
      path: '/settings',
      name: 'settings',
      component: SettingsFormComponent,
      beforeEnter: requireAuth,
    },
    {
      path: '/menus',
      name: 'menus',
      component: MenusFormComponent,
      beforeEnter: requireAuth,
    },
    {
      path: '/menus/:menuId',
      name: 'menu',
      component: MenuFormComponent,
      beforeEnter: requireAuth,
      props: true,
    },
    {
      path: '/screens',
      name: 'screens',
      component: ScreensFormComponent,
      beforeEnter: requireAuth,
    },
    {
      path: '/screens/:screenId',
      name: 'screen',
      component: ScreenFormComponent,
      beforeEnter: requireAuth,
      props: true,
    },
    {
      path: '/gallery',
      name: 'gallery',
      component: GalleryFormComponent,
      beforeEnter: requireAuth,
    },
    {
      path: '/shop',
      name: 'shop',
      component: ShopFormComponent,
      beforeEnter: requireAuth,
    },
    {
      path: '/products',
      name: 'products',
      component: ProductsFormComponent,
      beforeEnter: requireAuth,
      props: true,
    },
    {
      path: '/products/:productId',
      name: 'product',
      props: true,
      component: ProductFormComponent,
      beforeEnter: requireAuth,
    },
    {
      path: '/channels/',
      name: 'channels',
      component: ChannelsFormComponent,
      beforeEnter: requireAuth,
    },
    {
      path: '/devices/',
      name: 'devices',
      component: TelevisionsFormComponent,
      beforeEnter: requireAuth,
    },
  ],
};
