<template>
  <div v-if="screen">
    <h1>Screen</h1>

    <!-- show-checkbox -->
    <!-- <el-tree
      :data="screen"
      :draggable="true"
      default-expand-all
      node-key="id"
      ref="tree"
      highlight-current
      :props="defaultProps">
    </el-tree> -->

    <!-- <div>
      <div :key="language" v-for="{language, text} in getScreenTitles(screen)">{{ language }}: {{ text }}</div>
    </div> -->

    {{ screen.title.en || screen.title.hu }}

    <h5>Background</h5>
    <div style="width: 300px; height: 200px; text-align: center; border: solid 1px #cccccc; border-radius: 3px; cursor: pointer;" @click="dialogGalleryVisible = true">
      <img v-if="backgroundId" :src="getImageRedirectUrl(backgroundId)" style="max-width: 100%; max-height: 100%;" />
      <div v-if="!backgroundId">Select image</div>
    </div>

    <br>

    <el-button :disabled="saving" @click="saveContent">Save</el-button>

    <el-dialog title="Select background" top="50px" :visible.sync="dialogGalleryVisible">
      <!-- Background selection is currently disabled -->

      <div style="max-height: 75vh;">
        <el-table :data="galleryItems" height="60vh">
          <el-table-column>
            <template slot-scope="scope">
              <div :style="{ height: '200px', width: '250px', backgroundSize: 'contain', backgroundPosition: 'center center', backgroundRepeat: 'no-repeat', backgroundImage: `url(${getImageRedirectUrl(scope.row.id)})` }">
              </div>
            </template>
          </el-table-column>

          <el-table-column label="Tags">
            <template slot-scope="scope">
              <el-tag :key="tag" v-for="tag in scope.row.tags">{{ tag }}</el-tag>
            </template>
          </el-table-column>

          <el-table-column property="name" label="Name" width="200">
            <template slot-scope="scope">
              <el-button type="primary" plain :disabled="scope.row.id === backgroundId" @click="setImage(scope.row.id)">Select</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-dialog>

  </div>
</template>

<script>

import { logout } from '../../utils/auth.js';
import { getImageRedirectUrl, getScreen, listImages, patchScreen } from '../../api/';

export default {
  name: 'ScreenFormComponent',
  props: ['screenId'],

  created () {
    //
  },

  mounted () {

    Promise
      .all([
        getScreen(this.screenId).then((res) => res),
        listImages()
          .then((result) => {
            return result.data
              .map((image) => {
                const uri = 'https://vision-editor-data.ams3.digitaloceanspaces.com/images';
                return {
                  ...image,
                  url: `${uri}/${image.id}.${image.extension}`,
                };
              });
          }),
      ])
      .then(([screen, images]) => {
        this.screen = screen;
        this.backgroundId = screen.background_id;
        this.galleryItems = images;
      })
      .catch((err) => {
        if (err.response.status === 401) {
          logout();
          this.$router.push({ name: 'login' });
        }
      });
  },

  data() {
    return {
      screen: null,
      saving: false,
      backgroundId: null,
      galleryItems: [],
      dialogGalleryVisible: false,
    };
  },

  methods: {
    getImageRedirectUrl (id) {
      return getImageRedirectUrl(id);
    },
    saveContent () {
      patchScreen(this.screenId, { background_id: this.backgroundId })
      .then(() => {
        this.$message({
          message: 'Screen updated successfully.',
          type: 'success'
        });
      })
      .catch(() => {
        this.$message({
          message: 'Screen update failed.',
          type: 'error'
        });
      });

    },

    getScreenTitles (screen) {
      const languages = Object.keys(screen.title);

      return languages
        .map((language) => {
          return {
            language,
            text: screen.title[language] || '',
          };
        });
    },

    setImage (imageId) {
      this.backgroundId = imageId;
      this.dialogGalleryVisible = false;
    },

  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* h1, h2 {
  font-weight: normal;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
} */
</style>
