<template>
  <el-container class="wrapper" id="app">
    <el-header style="text-align: right;">
      <h1 id="header" style="line-height: 60px; margin: 0; float: left;">
        Vision Editor
        <!-- <img src="./assets/logo_header.png" style="vertical-align: middle; height: 20px;" alt="Vision" /> -->

        <!-- <el-select value="en" placeholder="Please select content language">
          <el-option label="English" value="en"></el-option>
          <el-option label="German" value="de"></el-option>
          <el-option label="Hungarian" value="hu"></el-option>
          <el-option label="French" value="fr"></el-option>
          <el-option label="Italian" value="it"></el-option>
        </el-select> -->
      </h1>

      <!-- <el-select v-model="form.region" placeholder="please select your zone">
        <el-option label="Zone one" value="shanghai"></el-option>
        <el-option label="Zone two" value="beijing"></el-option>
      </el-select> -->

      <el-button
        style=""
        type="text"
        v-if="isLoggedIn()"
        @click="logout()"
      >Logout</el-button>


    </el-header>

    <el-container>
      <el-aside width="200px" v-if="isLoggedIn()">

        <el-menu v-if="isLoggedIn()"
          :router="true"
          :collapse="false"
          >
          <el-menu-item index="/overview">
            <font-awesome-icon class="menu-icon" :icon="['far', 'tachometer-alt']" fixed-width />
            <span>Summary</span>
          </el-menu-item>
          <el-menu-item index="/screens">
            <font-awesome-icon class="menu-icon" :icon="['far', 'window-restore']" fixed-width />
            <span>Screens</span>
          </el-menu-item>
          <el-menu-item index="/menus">
            <font-awesome-icon class="menu-icon" :icon="['far', 'list']" fixed-width />
            <span>Menu</span>
          </el-menu-item>
          <!-- <el-menu-item index="/contents">
            <i class="el-icon-document"></i>
            <span>Contents</span>
          </el-menu-item> -->
          <el-menu-item index="/shop" :disabled="!siteId || siteId !== 'c5628cbd-8b33-446f-96ea-c8d73f948600'">
            <font-awesome-icon class="menu-icon" :icon="['far', 'shopping-bag']" fixed-width />
            <span>Shop</span>
          </el-menu-item>
          <el-menu-item index="/products" :disabled="!siteId || siteId !== 'c5628cbd-8b33-446f-96ea-c8d73f948600'">
            <font-awesome-icon class="menu-icon" :icon="['far', 'shopping-bag']" fixed-width />
            <span>Products</span>
          </el-menu-item>
          <el-menu-item index="/gallery">
            <font-awesome-icon class="menu-icon" :icon="['far', 'images']" fixed-width />
            <span>Gallery</span>
          </el-menu-item>
          <el-menu-item index="/applications" disabled>
            <font-awesome-icon class="menu-icon" :icon="['far', 'play']" fixed-width />
            <span>Applications</span>
          </el-menu-item>
          <el-menu-item index="/devices">
            <font-awesome-icon class="menu-icon" :icon="['far', 'tv']" fixed-width />
            <span>Devices</span>
          </el-menu-item>
          <el-menu-item index="/channels">
            <font-awesome-icon class="menu-icon" :icon="['far', 'broadcast-tower']" fixed-width />
            <span>Channels</span>
          </el-menu-item>
          <el-menu-item index="/settings" disabled>
            <font-awesome-icon class="menu-icon" :icon="['far', 'sliders-h']" fixed-width />
            <span>Settings</span>
          </el-menu-item>
          <el-menu-item index="/builds">
            <font-awesome-icon class="menu-icon" :icon="['far', 'archive']" fixed-width />
            <span>Builds</span>
          </el-menu-item>
          <el-menu-item index="/user" disabled>
            <font-awesome-icon class="menu-icon" :icon="['far', 'user']" fixed-width />
            <span>User</span>
          </el-menu-item>
        </el-menu>
      </el-aside>

      <!-- Main View -->
      <el-container>
        <el-main>
          <router-view/>
        </el-main>
      </el-container>
      <!-- End Main View -->

    </el-container>
  </el-container>
</template>

<script>
// import HelloWorld from './components/HelloWorld.vue'
import { mapState } from 'vuex';

import {
  isLoggedIn,
  logout as performLogout,
} from './utils/auth';

export default {
  name: 'app',
  components: {
    // HelloWorld
  },
  computed: {
    ...mapState(['siteId'])
  },
  methods: {
    isLoggedIn,

    logout () {
      performLogout();
      this.$router.push({ name: 'login' });

      this.$message({
        message: 'Logged out successfully.',
        type: 'success'
      });
    },
  },
}
</script>

<style>
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  margin: 0;
}

#header {
  background-color: inherit;
}

.menu-icon {
  margin-right: 5px;
}
</style>
