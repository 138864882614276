<template>
  <div>
    <h1>Settings</h1>

    <h5>Languages</h5>
    <ul>
      <li>English</li>
      <li>Hungarian</li>
      <li>German</li>
      <li>Russian</li>
      <li>French</li>
      <li>Italian</li>
    </ul>

    <h5>Features</h5>
    <ul>
      <li>Shop</li>
      <li>Contents</li>
      <li>PMS</li>
      <li>Applications</li>
    </ul>

    <h5>Configuration</h5>
    <ul>
      <li>Soft AP: enabled</li>
      <li>Wake On LAN (magic): start</li>
      <li>Instant Power: on</li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'SettingsFormComponent',
  data() {
    return {
      msg: 'Welcome to Your Vue.js App',
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* h1, h2 {
  font-weight: normal;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
} */
</style>
