<template>
  <div>
    <h1>Screens</h1>

    <el-tree
      :data="screens"
      node-key="id"
      default-expand-all
      highlight-current
      :props="defaultProps"
      :expand-on-click-node="false">
      <span class="custom-tree-node" slot-scope="{ node, data }">
        <span>
          <font-awesome-icon :class="['menu-icon', `menu-icon-${data.type}`]" :icon="getIcon(data)" fixed-width size="sm" />
          <router-link class="link" :to="{ name: 'screen', params: { screenId: data.id } }">
            {{ getTitle(data) }}
          </router-link>

          <!-- <span style="font-size: 60%; margin-left: 20px;">{{ data.id }}</span> -->

          <el-popover
            v-if="data.background_id && galleryItems"
            placement="top-start"
            title=""
            width="200"
            trigger="hover">
            <img
              :src="galleryItems.find((img) => img.id === data.background_id).url"
              style="width: 100%;"
            />
            <i slot="reference" class="el-icon-picture"></i>
         </el-popover>


        </span>
        <!-- <el-tag size="mini">content</el-tag> -->
        <span>
          <!-- <el-button
            type="text"
            size="mini"
            @click="() => append(data)">
            Append
          </el-button>
          <el-button
            type="text"
            size="mini"
            @click="() => remove(node, data)">
            Delete
          </el-button> -->
        </span>
      </span>
    </el-tree>

  </div>
</template>

<script>

import { getScreens, listImages } from '../../api/';

export default {
  name: 'ScreensFormComponent',

  created () {
    //
  },

  mounted () {
    getScreens()
      .then((response) => {
        const screens = response.data;
        this.screens = screens;
      });

    listImages()
      .then((result) => {
        return result.data
          .map((image) => {
            const uri = 'https://vision-editor-data.ams3.digitaloceanspaces.com/images';
            return {
              ...image,
              url: `${uri}/${image.id}.${image.extension}`,
            };
          });
      })
      .then((items) => {
        this.galleryItems = items;
      });
  },

  data() {
    return {
      screens: [],
      defaultProps: {
        children: 'children',
        label: 'label',
        disabled: function (data) { // (data, node)
          return data.id === 2;
        },
      },
      galleryItems: null,
    };
  },

  methods: {
    getIcon (menu) {
      switch (menu.type) {
        case 'welcome':
          return ['far', 'handshake'];
        case 'information':
          return ['far', 'home-alt'];
        default:
          return ['far', 'star'];
      }
    },
    getTitle (menu) {
      switch (menu.type) {
        case 'welcome':
          return 'Welcome';
        case 'information':
          return 'Information Menu';
        default:
          return ['far', 'star'];
      }
    },

  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* h1, h2 {
  font-weight: normal;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
} */


.menu-icon-content {
  color: #409EFF;
}

.link {
  text-decoration: none;
  color: inherit;
}

.link:hover {
  text-decoration: underline;
}
</style>
