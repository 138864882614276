<template>
  <div>
    <h1>Products</h1>

    <el-button type="primary" icon="el-icon-plus" @click="addProduct()">New Product</el-button>

    <el-table
      :data="products"
      stripe
      style="width: 100%">
      <el-table-column
        label="Name"
      >
        <template slot-scope="scope">

          <el-tooltip effect="dark" :content="scope.row.name.en" placement="top-start">
            <div>
              <router-link slot="reference" :to="{ name: 'product', params: { productId: scope.row.id }}">
                <span style="text-overflow: ellipsis; overflow: hidden; white-space: nowrap; display: block;">{{ scope.row.name.en }}</span>
              </router-link>
            </div>
          </el-tooltip>

        </template>
      </el-table-column>
      <el-table-column
        label="Category"
        >
        <template slot-scope="scope">
          <el-tag v-if="scope.row.category">{{ scope.row.category }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column
        label="Price"

        align="right">
        <template slot-scope="scope">
          {{ scope.row.price }} HUF
        </template>
      </el-table-column>
      <el-table-column
        label=""
        align="right">
        <template slot-scope="scope">
          <el-button-group>
            <el-button icon="el-icon-delete" @click="deleteProduct(scope.row.id)"></el-button>
          </el-button-group>
        </template>
      </el-table-column>

    </el-table>

    <el-dialog
      title="Warning"
      :visible.sync="productDeleteDialogVisible"
      width="30%"
      center>
      <span>Are you sure you want to delete <span style="bold">"{{ productNameToDelete }}"</span>?</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancelDeletion()">Cancel</el-button>
        <el-button type="primary" @click="confirmDeletion()">Confirm</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>

import { listProducts, deleteProduct } from '../../api/';

export default {
  name: 'ProductsFormComponent',
  data() {
    return {
      editing: 0,
      products: [],
      categoryFilter: null,
      defaultProps: {},
      productIdToDelete: null,
      productNameToDelete: '',
      productDeleteDialogVisible: false,
    };
  },

  mounted () {

    this.categoryFilter = this.$route.query.category || null;

    listProducts()
      .then((response) => {
        this.products = response.data
          .filter((product) => {
            if (!this.categoryFilter) {
              return true;
            }

            return (product.category === this.categoryFilter);
          });
      });
  },

  methods: {
    toggleEditor (product) {
      if (!product) {
        this.editing = null;
        return;
      }

      this.editing = product.id;
    },

    addProduct () {
      this.$router.push({
        name: 'product',
        params: { productId: 'new' },
        query: {
          category: this.$route.query.category || null,
        },
      });
    },

    deleteProduct (productId) {
      const productToDelete = this.products.find((p) => p.id === productId);

      if (!productToDelete) {
        return;
      }

      this.productNameToDelete = productToDelete.name.en;
      this.productIdToDelete = productId;
      this.productDeleteDialogVisible = true;
    },

    cancelDeletion () {
      this.productDeleteDialogVisible = false;
    },
    confirmDeletion () {

      deleteProduct(this.productIdToDelete)
        .then(() => {
          this.productDeleteDialogVisible = false;
          this.products = this.products
            .filter((p) => p.id !== this.productIdToDelete);

          this.$message({
            message: 'Product deleted successfully.',
            type: 'success'
          });

          this.productIdToDelete = null;
          this.productNameToDelete = '';
        })
        .catch(() => {
          this.productDeleteDialogVisible = false;
          this.productIdToDelete = null;
          this.productNameToDelete = '';

          this.$message({
            message: 'Product could not be removed.',
            type: 'error'
          });
        });
    },
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* h1, h2 {
  font-weight: normal;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
} */
</style>
