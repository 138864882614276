<template>
  <div class="editor-container">
    <div v-if="editor" class="editor-controls">
      <div>

        <button @click="editor.chain().focus().toggleHeading({ level: 1 }).run()" :class="{ 'is-active': editor.isActive('heading', { level: 1 }) }">
          <font-awesome-icon :icon="['fal', 'h1']" fixed-width />
        </button>
        <button @click="editor.chain().focus().toggleHeading({ level: 2 }).run()" :class="{ 'is-active': editor.isActive('heading', { level: 2 }) }">
          <font-awesome-icon :icon="['fal', 'h2']" fixed-width />
        </button>
        <button @click="editor.chain().focus().toggleHeading({ level: 3 }).run()" :class="{ 'is-active': editor.isActive('heading', { level: 3 }) }">
          <font-awesome-icon :icon="['fal', 'h3']" fixed-width />
        </button>
        <button @click="editor.chain().focus().toggleHeading({ level: 4 }).run()" :class="{ 'is-active': editor.isActive('heading', { level: 4 }) }">
          <font-awesome-icon :icon="['fal', 'h4']" fixed-width />
        </button>

        <span class="spacer" />

        <button @click="editor.chain().focus().toggleBold().run()" :class="{ 'is-active': editor.isActive('bold') }">
          <font-awesome-icon :icon="['fal', 'bold']" fixed-width />
        </button>
        <button @click="editor.chain().focus().toggleItalic().run()" :class="{ 'is-active': editor.isActive('italic') }">
          <font-awesome-icon :icon="['fal', 'italic']" fixed-width />
        </button>
        <button @click="editor.chain().focus().toggleStrike().run()" :class="{ 'is-active': editor.isActive('strike') }">
          <font-awesome-icon :icon="['fal', 'strikethrough']" fixed-width />
        </button>
        <button @click="editor.chain().focus().toggleCode().run()" :class="{ 'is-active': editor.isActive('code') }">
          <font-awesome-icon :icon="['fal', 'code']" fixed-width />
        </button>

        <span class="spacer" />

        <!-- <button @click="editor.chain().focus().unsetAllMarks().run()">
          clear marks
        </button> -->
        <!-- <button @click="editor.chain().focus().clearNodes().run()">
          clear nodes
        </button> -->
        <!-- <button @click="editor.chain().focus().setParagraph().run()" :class="{ 'is-active': editor.isActive('paragraph') }">
          paragraph
        </button> -->

        <button @click="editor.chain().focus().setTextAlign('left').run()" :class="{ 'is-active': editor.isActive({ textAlign: 'left' }) }">
          <font-awesome-icon :icon="['fal', 'align-left']" fixed-width />
        </button>
        <button @click="editor.chain().focus().setTextAlign('center').run()" :class="{ 'is-active': editor.isActive({ textAlign: 'center' }) }">
          <font-awesome-icon :icon="['fal', 'align-center']" fixed-width />
        </button>
        <button @click="editor.chain().focus().setTextAlign('right').run()" :class="{ 'is-active': editor.isActive({ textAlign: 'right' }) }">
          <font-awesome-icon :icon="['fal', 'align-right']" fixed-width />
        </button>

        <span class="spacer" />

        <button @click="editor.chain().focus().toggleBulletList().run()" :class="{ 'is-active': editor.isActive('bulletList') }">
          <font-awesome-icon :icon="['fal', 'list-ul']" fixed-width />
        </button>
        <button @click="editor.chain().focus().toggleOrderedList().run()" :class="{ 'is-active': editor.isActive('orderedList') }">
          <font-awesome-icon :icon="['fal', 'list-ol']" fixed-width />
        </button>

        <span class="spacer" />


        <!-- <button @click="editor.chain().focus().toggleCodeBlock().run()" :class="{ 'is-active': editor.isActive('codeBlock') }">
          code block
        </button> -->
        <button @click="editor.chain().focus().toggleBlockquote().run()" :class="{ 'is-active': editor.isActive('blockquote') }">
          <font-awesome-icon :icon="['fal', 'quote-right']" fixed-width />
        </button>
        <button @click="editor.chain().focus().setHorizontalRule().run()">
          <font-awesome-icon :icon="['fal', 'horizontal-rule']" fixed-width />
        </button>

        <span class="spacer" />

        <!-- <button @click="editor.chain().focus().setHardBreak().run()">
          hard break
        </button> -->

        <button @click="editor.chain().focus().insertTable({ rows: 3, cols: 3, withHeaderRow: false }).run()">
          <font-awesome-icon :icon="['fal', 'table']" fixed-width />
        </button>

        <button @click="openImageDialog()">
          <font-awesome-icon :icon="['fal', 'images']" fixed-width />
        </button>

        <span class="spacer" />


        <button @click="editor.chain().focus().undo().run()">
          <font-awesome-icon :icon="['fal', 'undo']" fixed-width />
        </button>
        <button @click="editor.chain().focus().redo().run()">
          <font-awesome-icon :icon="['fal', 'redo']" fixed-width />
        </button>
      </div>

      <div v-if="isTableSelected()">
        <button @click="editor.chain().focus().addColumnBefore().run()" :disabled="!editor.can().addColumnBefore()">
          add Column Before
        </button>
        <button @click="editor.chain().focus().addColumnAfter().run()" :disabled="!editor.can().addColumnAfter()">
          add Column After
        </button>
        <button @click="editor.chain().focus().deleteColumn().run()" :disabled="!editor.can().deleteColumn()">
          delete Column
        </button>
        <button @click="editor.chain().focus().addRowBefore().run()" :disabled="!editor.can().addRowBefore()">
          addRowBefore
        </button>
        <button @click="editor.chain().focus().addRowAfter().run()" :disabled="!editor.can().addRowAfter()">
          addRowAfter
        </button>
        <button @click="editor.chain().focus().deleteRow().run()" :disabled="!editor.can().deleteRow()">
          delete Row
        </button>
        <button @click="editor.chain().focus().deleteTable().run()" :disabled="!editor.can().deleteTable()">
          delete Table
        </button>
      </div>
    </div>
    <editor-content :editor="editor" />

    <el-dialog title="Select image" :visible.sync="dialogGalleryVisible">
      <!-- Background selection is currently disabled -->

      Filter by tag <el-select v-model="filteredTags" multiple placeholder="Select">

      <el-option v-for="tag in availableImageTags" :key="tag" :label="tag" :value="tag">
      </el-option>
      </el-select>


      <el-table :data="filteredGalleryImages">
        <el-table-column>
          <template slot-scope="scope">
            <div :style="{ height: '200px', width: '250px', backgroundSize: 'cover', backgroundPosition: 'center center', backgroundRepeat: 'no-repeat', backgroundImage: `url(${scope.row.url})` }">
            </div>
          </template>
        </el-table-column>

        <el-table-column property="name" label="Name" width="200">
          <template slot-scope="scope">
            <el-button type="primary" plain @click="setImage(scope.row)">Select</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>

<script>
import { Editor, EditorContent } from '@tiptap/vue-2';
import StarterKit from '@tiptap/starter-kit';
import Document from '@tiptap/extension-document';
import Paragraph from '@tiptap/extension-paragraph';
import Text from '@tiptap/extension-text';
import Image from '@tiptap/extension-image';
import Dropcursor from '@tiptap/extension-dropcursor';
import Table from '@tiptap/extension-table';
import TableRow from '@tiptap/extension-table-row';
import TableCell from '@tiptap/extension-table-cell';
import TableHeader from '@tiptap/extension-table-header';
import TextAlign from '@tiptap/extension-text-align';
import Placeholder from '@tiptap/extension-placeholder';

import {
  getImageRedirectUrl,
  listImages,
} from '../../api/';

export default {
  components: {
    EditorContent,
  },

  props: {
    value: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      editor: null,

      galleryItems: [],
      dialogGalleryVisible: false,

      filteredTags: [],
    }
  },

  watch: {
    value(value) {

      // HTML
      const isSame = this.editor.getHTML() === value;

      // JSON
      // const isSame = this.editor.getJSON().toString() === value.toString()

      if (isSame) {
        return
      }

      this.editor.commands.setContent(value, false)
    },
  },

  computed: {
    availableImageTags () {
      const uniqueTags = new Set();

      this.galleryItems.forEach((image) => {
        (image.tags || []).forEach((tag) => {
          uniqueTags.add(tag);
        });
      });

      return [...uniqueTags];
    },

    filteredGalleryImages () {

      if (!this.filteredTags.length) {
        return this.galleryItems;
      }

      return this.galleryItems
        .filter((image) => {
          if (!image.tags || !image.tags.length) {
            return false;
          }

          return this.filteredTags
            .every((filteredTag) => {
              return image.tags.includes(filteredTag);
            });
        });
    }
  },


  methods: {
    openImageDialog () {
      this.dialogGalleryVisible = true;
      // this.editor.chain().focus().setImage({ src: 'https://source.unsplash.com/8xznAGy4HcY/800x400' }).run()
    },
    setImage (image) {
      const src = getImageRedirectUrl(image.id);
      this.editor.chain().focus().setImage({ src }).run();

      this.dialogGalleryVisible = false;
    },
    isTableSelected () {
      return this.editor.can().addColumnBefore()
         || this.editor.can().addColumnAfter()
         || this.editor.can().deleteColumn()
         || this.editor.can().deleteRow()
         || this.editor.can().deleteTable()
         || this.editor.can().addRowBefore()
         || this.editor.can().addRowAfter();
    }
  },

  mounted() {

    listImages()
      .then((result) => {
        return result.data
          .map((image) => {
            // const uri = 'https://vision-editor-data.ams3.digitaloceanspaces.com/images';
            return {
              ...image,
              // url: `${uri}/${image.id}.${image.extension}`,
              url: getImageRedirectUrl(image.id),
            };
          });
      })
      .then((images) => {
        this.galleryItems = images;
      });


    this.editor = new Editor({
      extensions: [
        StarterKit,
        Document,
        Paragraph,
        Text,
        Image,
        Dropcursor,

        Table,
        TableRow,
        TableCell,
        TableHeader,

        TextAlign.configure({
          types: ['heading', 'paragraph'],
        }),

        Placeholder.configure({
          // Use a placeholder:
          placeholder: 'No content yet …',
        }),
      ],
      content: this.value,
      onUpdate: () => {
        // HTML
        this.$emit('input', this.editor.getHTML())

        // JSON
        // this.$emit('update:modelValue', this.editor.getJSON())
      },
    })
  },

  beforeUnmount() {
    this.editor.destroy()
  },
}
</script>

<style lang="scss">

.editor-controls {
  border-bottom: 1px solid #DCDFE6;
  padding: 5px;

   button, input, select {
    font-size: inherit;
    font-family: inherit;
    color: #000;
    margin: .1rem;
    border: 1px solid black;
    border-radius: .3rem;
    padding: .1rem .4rem;
    background: white;
    accent-color: black;


    &[disabled] {
      opacity: .3;
    }
  }

  .is-active {
    background: black;
    color: #fff;
  }

  .spacer {
    display: inline-block;
    content: "&nbsp;";
    width: 16px;
  }
}

/* Basic editor styles */
.ProseMirror {
  > * + * {
    margin-top: 0.75em;
  }

  ul,
  ol {
    padding: 0 1rem;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: 1.1;
  }

  code {
    background-color: rgba(#616161, 0.1);
    color: #616161;
  }

  pre {
    background: #0D0D0D;
    color: #FFF;
    font-family: 'JetBrainsMono', monospace;
    padding: 0.75rem 1rem;
    border-radius: 0.5rem;

    code {
      color: inherit;
      padding: 0;
      background: none;
      font-size: 0.8rem;
    }
  }

  img {
    max-width: 100%;
    height: auto;
  }

  blockquote {
    padding-left: 1rem;
    border-left: 2px solid rgba(#0D0D0D, 0.1);
  }

  hr {
    border: none;
    border-top: 2px solid rgba(#0D0D0D, 0.1);
    margin: 2rem 0;
  }

  table {
    border-collapse: collapse;
    table-layout: fixed;
    width: 100%;
    margin: 0;
    overflow: hidden;

    td,
    th {
      min-width: 1em;
      border: 2px solid #ced4da;
      padding: 3px 5px;
      vertical-align: top;
      box-sizing: border-box;
      position: relative;

      > * {
        margin-bottom: 0;
      }
    }

    th {
      font-weight: bold;
      text-align: left;
      background-color: #f1f3f5;
    }

    .selectedCell:after {
      z-index: 2;
      position: absolute;
      content: "";
      left: 0; right: 0; top: 0; bottom: 0;
      background: rgba(200, 200, 255, 0.4);
      pointer-events: none;
    }

    .column-resize-handle {
      position: absolute;
      right: -2px;
      top: 0;
      bottom: -2px;
      width: 4px;
      background-color: #adf;
      pointer-events: none;
    }
  }
}

.editor-container {
  border: 1px solid #DCDFE6;
  border-radius: 4px;
}
</style>
