import { getToken } from '../api/';

let loginStatus = false;
let accessToken = null;

export async function login (username, password) {

  return getToken(username, password)
    .then((response) => {
      accessToken = response.accessToken;
      loginStatus = true;
      return response;
    })
    .catch((ex) => {
      loginStatus = false;
      throw ex;
    })
    .then((response) => {
      return response;
    });
}

export function logout () {
  if (window.localStorage) {
    const ls = window.localStorage;
    ls.removeItem('token');
    ls.removeItem('token_expiry');
  }

  accessToken = null;
  loginStatus = false;
}

export function setAccessToken (_accessToken) {
  loginStatus = true;
  accessToken = _accessToken;
}

export function getAccessToken () {
  return accessToken;
}

export function isLoggedIn () {
  return loginStatus;
}

export function requireAuth(to, from, next) {

  const loggedIn = isLoggedIn();

  if (loggedIn) {
    next();
    return;
  }

  // if (window.localStorage) {
  //   const ls = window.localStorage;
  //   const accessToken = ls.getItem('token');
  //   const accessTokenExpiry = ls.getItem('token_expiry');
  //
  //   if (accessToken && accessTokenExpiry > (Date.now() + (6 * 60 * 60e3))) {
  //     setAccessToken(accessToken);
  //
  //     listSites()
  //       .then((response) => {
  //         const sites = response.data;
  //
  //         this.storeSites(sites);
  //         this.selectSite(sites[0].id);
  //
  //         next();
  //       })
  //       .catch((err) => {
  //         // Delete stored token on auth error
  //         if (err.response.status === 401) {
  //           logout();
  //         }
  //
  //         next({
  //           path: '/login',
  //           query: { redirect: to.fullPath }
  //         });
  //       });
  //     return;
  //   }
  // }

  next({
    path: '/login',
    query: { redirect: to.fullPath }
  });
}
