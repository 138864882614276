<template>
  <div>
    <h1>Content</h1>

    <h5>Content Type</h5>

    <el-select v-if="content" :value="contentType" disabled>
        <el-option :key="'text'" :label="'Text'" :value="'text'" />
        <el-option :key="'editor'" :label="'Editor'" :value="'editor'" />
        <el-option :key="'html'" :label="'Raw HTML'" :value="'html'" />
    </el-select>

    <h5>Content</h5>

    <el-tabs type="border-card">
      <template v-for="content in zzz">
        <el-tab-pane v-bind:key="content.language">
          <span slot="label">{{ content.language_text }} - {{ content.title }}</span>

          <template v-if="content">
            <div>

              <div v-if="contentType === 'text'">
                <el-input
                  type="textarea"
                  :autosize="{ minRows: 15, maxRows: 40 }"
                  :disabled="saving"
                  style="width: 100%;"
                  v-model="content.text"
                ></el-input>
              </div>

              <div v-if="contentType === 'html'">

                <el-alert
                  v-if="analyzeContent(content.text).forbidden"
                  title="WARNING"
                  type="warning"
                  description="The editor contains unsupported HTML tags."
                  :closable="false"
                  show-icon>
                </el-alert>

                <br>

                <el-input
                  type="textarea"
                  :autosize="{ minRows: 15, maxRows: 40 }"
                  :disabled="saving"
                  style="width: 100%;"
                  v-model="content.text"
                ></el-input>

                <br><br>

                Structure:

                <br>

                <el-tree
                  :props="{ children: 'children', label: 'label' }"
                  :data="analyzeContent(content.text).structure"
                  default-expand-all
                  node-key="id"
                >
                  <!-- code -->
                  <span class="custom-tree-node" slot-scope="{ node, data }">


                    <font-awesome-icon v-if="data.type === 'element'" :class="['menu-icon']" :icon="['fal', 'code']" fixed-width size="sm" />
                    <font-awesome-icon v-if="data.type === 'attribute'" :class="['menu-icon']" :icon="['fal', 'dollar-sign']" fixed-width size="sm" />
                    <font-awesome-icon v-if="data.type === 'handler'" :class="['menu-icon']" :icon="['fal', 'function']" fixed-width size="sm" />
                    <font-awesome-icon v-if="data.type === 'style'" :class="['menu-icon']" :icon="['fal', 'text-size']" fixed-width size="sm" />
                    <!-- <font-awesome-icon :class="['menu-icon']" :icon="['fal', 'text']" fixed-width size="sm" /> -->

                    <font-awesome-icon v-if="!data.erase && !data.warn && !data.forbidden" :class="['menu-icon', 'menu-icon-ok']" :icon="['fal', 'check']" fixed-width size="sm" />
                    <font-awesome-icon v-if="!!data.erase" :class="['menu-icon', 'menu-icon-ok']" :icon="['fal', 'eraser']" fixed-width size="sm" />
                    <font-awesome-icon v-if="!!data.warn" :class="['menu-icon', 'menu-icon-warn']" :icon="['fal', 'exclamation-triangle']" fixed-width size="sm" />
                    <font-awesome-icon v-if="!!data.forbidden" :class="['menu-icon', 'menu-icon-forbidden']" :icon="['fal', 'times-octagon']" fixed-width size="sm" />

                    {{ data.label }}
                  </span>
                </el-tree>

              </div>



              <div v-if="contentType === 'editor'">
                <TipTapEditor v-model="content.text" />
              </div>

            </div>
          </template>
        </el-tab-pane>
      </template>
    </el-tabs>

    <h5>Content Background Opacity</h5>

    <div>
      Override default:

      <el-switch :value="backgroundOpacity !== null" @input="toggleBackgroundOpacityOverride"></el-switch>

      <div>
        <el-slider style="margin-left: 40px; margin-right: 40px;" v-if="backgroundOpacity !== null" :disabled="backgroundOpacity === null" :min="0" :max="100" :value="backgroundOpacityValue" @input="backgroundOpacityUpdate" :marks="backgroundOpacityMarks" :format-tooltip="formatBackgroundOpacityTooltip"></el-slider>
      </div>

      <h5>Background</h5>
      <div style="width: 300px; height: 200px; text-align: center; border: solid 1px #cccccc; border-radius: 3px; cursor: pointer;" @click="dialogGalleryVisible = true">
        <img v-if="backgroundId" :src="getImageRedirectUrl(backgroundId)" style="max-width: 100%; max-height: 100%;" />
        <div v-if="!backgroundId">Select image</div>
      </div>
    </div>

    <br>

    <el-button :disabled="saving" @click="saveContent">Save</el-button>

    <el-dialog title="Select background" top="50px" :visible.sync="dialogGalleryVisible">
      <!-- Background selection is currently disabled -->

      <div style="max-height: 75vh;">
        <el-table :data="galleryItems" height="60vh">
          <el-table-column>
            <template slot-scope="scope">
              <div :style="{ height: '200px', width: '250px', backgroundSize: 'contain', backgroundPosition: 'center center', backgroundRepeat: 'no-repeat', backgroundImage: `url(${getImageRedirectUrl(scope.row.id)})` }">
              </div>
            </template>
          </el-table-column>

          <el-table-column label="Tags">
            <template slot-scope="scope">
              <el-tag :key="tag" v-for="tag in scope.row.tags">{{ tag }}</el-tag>
            </template>
          </el-table-column>

          <el-table-column property="name" label="Name" width="200">
            <template slot-scope="scope">
              <el-button type="primary" plain :disabled="scope.row.id === backgroundId" @click="setImage(scope.row.id)">Select</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-dialog>
  </div>
</template>

<script>

import TipTapEditor from './TipTapEditor.vue'

import {
  getContent,
  getMenu,
  patchMenu,
  listImages,
  updateContent,
  getConfigs,
  getImageRedirectUrl,
} from '../../api/';

export default {
  name: 'ContentFormComponent',
  props: [
    'contentId',
  ],
  components: {
    TipTapEditor,
  },
  mounted () {
    Promise
      .all([
        getContent(this.contentId),
        listImages()
          .then((result) => {
            return result.data
              .map((image) => {
                const uri = 'https://vision-editor-data.ams3.digitaloceanspaces.com/images';
                return {
                  ...image,
                  url: `${uri}/${image.id}.${image.extension}`,
                };
              });
          }),
        getMenu(this.contentId),
        getConfigs().then((res) => res.data),
      ])
      .then(([content, images, menu, configs]) => {

        this.backgroundId = menu.background_id;
        this.galleryItems = images;
        this.content = content;
        this.contentType = content.type;
        this.backgroundOpacity = content.opacity;

        const languageConfig = configs
          .find((c) => c.key === 'languages');

        const languages = languageConfig
          ? languageConfig.value
          : ['hu', 'en', 'de', 'ru', 'fr', 'it'];
        const languageText = this.$store.state.languageTexts;

        this.zzz = languages
          .map((lang) => {
            return {
              language: lang,
              language_text: languageText[lang],
              title: (menu && menu.title) ? menu.title[lang] : '',
              text: (content && content.content) ? content.content[lang] : '',
            };
          });

        this.saving = false;
      });
  },
  created () {
    // Disable save button
    this.saving = true;
  },
  destroyed () {

  },
  methods: {
    getImageRedirectUrl (id) {
      return getImageRedirectUrl(id);
    },
    // https://stackoverflow.com/questions/7935689/what-is-the-difference-between-children-and-childnodes-in-javascript
    analyzeContent (rawHtml) {
      const parser = new DOMParser()
      const doc = parser.parseFromString(rawHtml || '', 'text/html');

      function extractAttributes (element) {
        if (!element || !element.attributes) {
          return [];
        }
        // return [...element.attributes]
        //   .reduce((attrs, node) => { attrs[node.nodeName] = node.nodeValue; return attrs; }, {})

        return [...element.attributes]
          .map((node) => ({ key: node.nodeName, value: node.nodeValue }));
      }

      let counter = 0;

      const erasedElements = new Set([
        '',
        'title',
        'html',
        'body',
        'head',
      ]);

      const allowedElements = new Set([
        'p',
        'span',
        'img',
        'b',
        'br',
        'div',
        'em',
        'pre',
        'i',
        'li',
        'ul',
        'ol',
        's',

        'table',
        'th',
        'tr',
        'td',
        'thead',
        'tfoot',
        'tbody',
        'u',
        'ul',
        'h1',
        'h2',
        'h3',
        'h4',
        'h5',
        'h6',

        'hr',
      ]);

      // TODO: Limit style to the following:
      // "margin", "margin-left", "margin-top", "margin-bottom", "margin-right", "padding", "padding-left", "padding-top", "padding-bottom", "padding-right", "border", "font-size", "background-color", "color", "font-weight", "font-style", "font-family"

      const safeAttributes = new Set([
        'class',
        'style',
        // img, canvas, iframe, embed,
        'width', 'height',
        // textarea
        'cols', 'rows', 'wrap',

        // iframe
        'sandbox',

        'title',
        'alt',
      ]);

      let hasForbiddenElement = false;

      function extractX (x) {

        const attributes = extractAttributes(x);
        const children = [...(x.children || [])].map(extractX);

        const attributeMap = {
          style: 'style',
          class: 'style',

          onload: 'handler',
        };

        const lcTag = (x.tagName || '').toLowerCase();

        const localForbidden = !erasedElements.has(lcTag)
          && !allowedElements.has(lcTag);

        hasForbiddenElement = hasForbiddenElement || localForbidden;

        return {
          id: counter++,
          label: lcTag,
          type: 'element',
          forbidden: localForbidden,
          erase: !lcTag || lcTag === 'html' || lcTag === 'body' || lcTag === 'head',
          children: [
            ...attributes
              .map((obj) => ({
                id: counter++,
                type: attributeMap[obj.key] || 'attribute',
                label: obj.key,
                warn: !safeAttributes.has(obj.key) || attributeMap[obj.key] === 'handler',
              })),
            ...children,
          ],
        };
      }

      const structure = Array.from(doc.children || [])
        .map((element) => extractX(element));

      // console.log(extractX(doc));
      return {
        forbidden: hasForbiddenElement,
        structure: structure,
      };
    },
    backgroundOpacityUpdate (newValue) {
      if (this.backgroundOpacity === null) {
        return;
      }
      this.backgroundOpacity = newValue;
    },
    toggleBackgroundOpacityOverride (newValue) {
      if (newValue) {
        this.backgroundOpacity = this.defaultBackgroundOpacity;
      } else {
        this.backgroundOpacity = null;
      }
    },
    formatBackgroundOpacityTooltip (newValue) {
      return `${newValue}%`;
    },
    saveContent () {
      const payload = this.zzz.reduce((h, item) => {
        h.content = (h.content || {});
        h.content[item.language] = item.text;

        h.title = (h.title || {});
        h.title[item.language] = item.title;

        return h;
      }, {});

      payload.opacity = this.backgroundOpacity;

      updateContent(this.contentId, payload)
        .then(() => {
          this.saving = false;

          this.$message({
            message: 'Content updated successfully.',
            type: 'success'
          });
        })
        .catch(() => {
          this.saving = false;

          this.$message({
            message: 'Content update failed.',
            type: 'error'
          });
        });

      patchMenu(this.contentId, { background_id: this.backgroundId })
        .then(() => {
          this.$message({
            message: 'Content updated successfully.',
            type: 'success'
          });
        });
    },

    setImage (imageId) {
      this.backgroundId = imageId;
      this.dialogGalleryVisible = false;
    },
  },
  computed: {
    backgroundOpacityValue () {
      if (this.backgroundOpacity === null) {
        return this.defaultBackgroundOpacity;
      }

      return this.backgroundOpacity;
    },
  },
  data() {
    return {
      saving: false,
      backgroundId: null,
      backgroundOpacity: null,
      defaultBackgroundOpacity: 70,
      galleryItems: [],
      dialogGalleryVisible: false,
      content: null,
      contentType: 'text',

      backgroundOpacityMarks: {
        0: 'Transparent',
        70: 'Default',
        100: 'Opaque',
      },

      zzz: [],
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.menu-icon-content {
  color: #409EFF;
}

.menu-icon-warn {
  color: #E6A23C;
}

.menu-icon-forbidden {
  color: #F56C6C;
}

.menu-icon-ok {
  color: #67C23A;
}

/* h1, h2 {
  font-weight: normal;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color:} */
</style>
