<template>
  <div>
    <h1>Shop</h1>

    <el-tree
      :data="menu"
      node-key="id"
      default-expand-all
      highlight-current
      :props="defaultProps"
      :expand-on-click-node="false">
      <span class="custom-tree-node" slot-scope="{ node, data }">
        <span>
          <router-link :to="{ name: 'products', query: { category: data.category } }">
            {{ data.label }}
          </router-link>
        </span>
        <!-- <el-tag size="mini">content</el-tag> -->
        <span>
          <!-- <el-button
            type="text"
            size="mini"
            @click="() => append(data)">
            Append
          </el-button>
          <el-button
            type="text"
            size="mini"
            @click="() => remove(node, data)">
            Delete
          </el-button> -->
        </span>
      </span>
    </el-tree>
  </div>
</template>

<script>
// import { getShops } from '../../api/';

export default {
  name: 'ShopFormComponent',
  data() {
    return {
      images: [],
      menu: [
        {
          id: 1,
          label: 'Transfers',
          category: 'transfers',
        },
        {
          id: 2,
          label: 'Room Breakfast',
          category: 'breakfast',
        },
        {
          id: 3,
          label: 'Menu Drinks',
          category: 'menu-drinks',
        },
        {
          id: 4,
          label: 'Menu Wines',
          category: 'menu-wines',
        },
        {
          id: 5,
          label: 'Menu Sandwiches',
          category: 'menu-sandwiches',
        },
        {
          id: 6,
          label: 'Menu Starters',
          category: 'menu-starters',
        },
        {
          id: 7,
          label: 'Menu Soups',
          category: 'menu-soups',
        },
        {
          id: 8,
          label: 'Menu Salads',
          category: 'menu-salads',
        },
        {
          id: 9,
          label: 'Menu Main Dishes',
          category: 'menu-main-dishes',
        },
        {
          id: 10,
          label: 'Menu Desserts',
          category: 'menu-desserts',
        },
        {
          id: 11,
          label: 'Massage',
          category: 'massage',
        },
      ],
      defaultProps: {},
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* h1, h2 {
  font-weight: normal;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
} */
</style>
