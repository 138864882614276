<template>
  <div>
    <h1>Gallery</h1>

    Filter by tag <el-select v-model="filteredTags" multiple placeholder="Select">

      <el-option v-for="tag in availableTags" :key="tag" :label="tag" :value="tag">
      </el-option>
    </el-select>

    <el-table
      :data="filteredImages"
      stripe
      style="width: 100%">
      <el-table-column>
        <template slot-scope="scope">
          <div v-if="scope.row.type === 'image'" :style="{ height: '150px', backgroundSize: 'contain', backgroundPosition: 'center center', backgroundRepeat: 'no-repeat', backgroundImage: `url(${scope.row.url})` }">
          </div>

          <el-upload
            class="image-uploader"
            v-if="scope.row.type === 'upload'"
            action="https://editor.visionadmin.com/api/v1/images:upload"
            list-type="picture-card"
            :accept="'image/jpeg'"
            :no-on-preview="handlePictureCardPreview"
            :on-remove="handleRemove"
            :on-success="uploadSucceeded"
            :http-request="uploadFile">
            <i class="el-icon-plus"></i>
          </el-upload>
        </template>
      </el-table-column>
      <el-table-column
        label="Tags"
        >
          <template slot-scope="scope">
            <el-tag :key="tag" v-for="tag in scope.row.tags">{{ tag }}</el-tag>
          </template>
      </el-table-column>
      <el-table-column
        label=""

        align="right">
        <template slot-scope="scope">
          <el-button-group v-if="scope.row.type === 'image'">
            <el-button @click="removeImage(scope.row)" icon="el-icon-delete"></el-button>
            <el-button @click="openImage(scope.row)" icon="el-icon-download">
              <router-link :to="scope.row.url" target="_blank"></router-link>
            </el-button>

          </el-button-group>
        </template>
      </el-table-column>
    </el-table>

  </div>
</template>

<script>

import { getImageRedirectUrl, listImages, uploadGalleryImage } from '../../api/';


export default {
  name: 'GalleryFormComponent',

  created () {
    this.reloadImages();
  },

  data() {
    return {
      uploads: [],
      images: [],

      dialogImageUrl: '',
      dialogVisible: false,

      filteredTags: [],
    };
  },

  computed: {
    availableTags () {
      const uniqueTags = new Set();

      this.images.forEach((image) => {
        (image.tags || []).forEach((tag) => {
          uniqueTags.add(tag);
        });
      });

      return [...uniqueTags];
    },

    filteredImages () {

      if (!this.filteredTags.length) {
        return this.images;
      }

      return this.images
        .filter((image) => {
          if (!image.tags || !image.tags.length) {
            return false;
          }

          return this.filteredTags
            .every((filteredTag) => {
              return image.tags.includes(filteredTag);
            });
        });
    }
  },

  methods: {
    getImageRedirectUrl (id) {
      return getImageRedirectUrl(id);
    },

    openImage (image) {
      window.open(image.url, '_blank');
    },

    removeImage (/* image */) {
      this.$message.error('This image is currently in use. Please wait for all application updates to complete.');
    },

    uploadFile (event) {
      uploadGalleryImage(event.file)
        .then((res) => {
          event.onSuccess(res);
        })
        .catch((ex) => {
          event.onError(ex);
        });
    },

    uploadSucceeded () { // (res, file)
      // this.imageUrl = URL.createObjectURL(file.raw);
      this.reloadImages();
    },

    reloadImages () {
      listImages()
        .then((result) => {
          const data = result.data
            .map((image) => {
              const uri = getImageRedirectUrl(image.id);
              return {
                ...image,
                url: uri,
                type: 'image',
              };
            });

          this.images = [
            { type: 'upload' },
            ...data,
          ];
        });
    },

    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    }
  }
};
</script>

<style>
.image-uploader .el-upload {
  width: 100% !important;
}
</style>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* h1, h2 {
  font-weight: normal;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
} */
</style>
