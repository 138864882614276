<template>
  <div>
    <h1>Login</h1>

    <el-row type="flex" class="row-bg">
      <el-col :xs="{ offset: 0, span: 24 }" :md="{ offset: 3, span: 18 }" :span="12" :offset="6">
        <el-form ref="form" :model="form" label-width="120px" @submit.native.prevent>

          <el-form-item v-if="underMaintenance">
            <el-alert
               title="Under maintenance"
               description="Logins and device updates are disabled"
               type="error"
               show-icon>
             </el-alert>
          </el-form-item>

          <el-form-item label="Username">
            <el-input v-model="form.username"></el-input>
          </el-form-item>
          <el-form-item label="Password">
            <el-input type="password" v-model="form.password" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button :disabled="underMaintenance" type="primary" @click="onLogin">Login</el-button>
            <el-button @click="onCancel">Clear</el-button>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>

  </div>
</template>

<script>

import { mapActions } from 'vuex';

import { listSites } from '../../api/';
import { login, setAccessToken } from '../../utils/auth';

export default {
  name: 'LoginFormComponent',
  created ()  {

    this.redirectTo = this.$route.query.redirect;

    if (window.localStorage) {
      const ls = window.localStorage;
      this.form.username = ls.getItem('username');

      const accessToken = ls.getItem('token');
      const accessTokenExpiry = ls.getItem('token_expiry');

      if (accessToken && accessTokenExpiry > (Date.now() + (6 * 60 * 60e3))) {
        setAccessToken(accessToken);

        listSites()
          .then((response) => {

            const sites = response.data;

            this.storeSites(sites);
            this.selectSite(sites[0].id);

            if (this.redirectTo) {
              this.$router.push({ path: this.redirectTo });
            } else {
              this.$router.push({ name: 'menu' });
            }
          })
          .catch((err) => {
            // Delete stored token on auth error
            if (err.response.status === 401) {
              ls.removeItem('token');
              ls.removeItem('token_expiry');
            }
          });

      } else {
        ls.removeItem('token');
        ls.removeItem('token_expiry');
      }
    }
  },

  data() {
    return {
      defaultProps: {},
      underMaintenance: false,
      redirectTo: null,
      form: {
        username: '',
        password: '',
      },
    };
  },
  methods: {
    onLogin () {
      const username = this.form.username;
      const password = this.form.password;

      // Reset password on submit
      this.form.password = '';

      // this.$message({
      //   message: 'Bejelentkezés sikeres.',
      //   showClose: true,
      //   type: 'success',
      // });

      login(username, password)
        .then((response) => {

          if (window.localStorage) {
            const ls = window.localStorage;

            ls.setItem('username', username);
            ls.setItem('token', response.accessToken);
            ls.setItem('token_expiry', response.expires);
          }

          listSites()
            .then((response) => {

              const sites = response.data;

              this.storeSites(sites);
              this.selectSite(sites[0].id);

              if (this.redirectTo) {
                this.$router.push({ path: this.redirectTo });
              } else {
                this.$router.push({ name: 'menu' });
              }
            });
        })
        .catch((ex) => {
          console.error(ex);
          this.$message({
            message: 'Login error. Please check your login details.',
            showClose: true,
            type: 'error',
          });
        });
    },

    onCancel () {
      this.form.username = '';
      this.form.password = '';

      // Remove details on clear
      if (window.localStorage) {
        const ls = window.localStorage;
        ls.removeItem('username');
      }
    },

    ...mapActions([
      'storeSites', 'selectSite',
    ]),
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* h1, h2 {
  font-weight: normal;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
} */
</style>
