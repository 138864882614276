<template>
  <div>
    <h1>Channels</h1>

    <el-table
      :data="channelList"
      v-loading="loading"
      stripe
      style="width: 100%">
      <el-table-column
        prop="num"
        label="Num"
        >
      </el-table-column>
      <el-table-column
        prop="name"
        label="Name"
        >
      </el-table-column>
      <el-table-column
        prop="textType"
        label="Type"
        >
      </el-table-column>
      <el-table-column
        prop="parameterText"
        label="Parameters"
        >
      </el-table-column>

      <el-table-column
        label=""

        align="right">
        <!-- <template slot-scope="scope"> -->
        <template>

          <el-button-group>
            <el-button disabled icon="el-icon-delete"></el-button>
            <el-button disabled icon="el-icon-arrow-down"></el-button>
            <el-button disabled icon="el-icon-arrow-up"></el-button>
          </el-button-group>
        </template>
      </el-table-column>
    </el-table>

  </div>
</template>

<script>

import { listChannels } from '../../api/';

const typeMap = {
  'rf_freq:48': 'DVB-C',
  'ip:': 'IP',
};

export default {
  name: 'ChannelsFormComponent',
  data() {
    return {
      editing: 0,
      loading: false,
      channelList: [],
    };
  },

  created () {
    this.loading = true;
  },

  mounted () {

    function formatParams (params) {
      switch (params.type) {
        case 'rf_freq':
          return `${(params.freq / 1e6)}MHz ${params.prog}`;
        case 'ip':
          return `${params.ip}:${params.port}`;
        default:
          return '-';
      }
    }

    this.loading = true;
    listChannels()
      .then((response) => {
        this.loading = false;

        this.channelList = response.data
          .map((item, i) => {

            const params = item.parameters;

            return {
              ...item,

              num: i + 1,
              textType: typeMap[params.type + ':' + params.bType],
              parameterText: formatParams(params),
            };

          });
      })
      .catch(() => {
        this.loading = false;
      });
  },

  methods: {
    //
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* h1, h2 {
  font-weight: normal;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
} */
</style>
