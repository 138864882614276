<template>
  <div v-if="menu">
    <h1>Menu - {{ menu.title.en || menu.title.hu }}</h1>

    <h5>Properties</h5>

    Opacity: <el-slider v-model="backgroundOpacity" :step="1" :min="0" :max="100"></el-slider>

    <h5>Background</h5>
    <div style="width: 300px; height: 200px; text-align: center; border: solid 1px #cccccc; border-radius: 3px; cursor: pointer;" @click="dialogGalleryVisible = true">
      <img v-if="backgroundId" :src="getImageRedirectUrl(backgroundId)" style="max-width: 100%; max-height: 100%;" />
      <div v-if="!backgroundId">Select image</div>
    </div>

    <br>

    <el-button :disabled="saving" @click="saveContent">Save</el-button>

    <el-dialog title="Select background" top="50px" :visible.sync="dialogGalleryVisible">
      <!-- Background selection is currently disabled -->

      <div style="max-height: 75vh;">
        <el-table :data="galleryItems" height="60vh">
          <el-table-column>
            <template slot-scope="scope">
              <div :style="{ height: '200px', width: '250px', backgroundSize: 'contain', backgroundPosition: 'center center', backgroundRepeat: 'no-repeat', backgroundImage: `url(${getImageRedirectUrl(scope.row.id)})` }">
              </div>
            </template>
          </el-table-column>

          <el-table-column label="Tags">
            <template slot-scope="scope">
              <el-tag :key="tag" v-for="tag in scope.row.tags">{{ tag }}</el-tag>
            </template>
          </el-table-column>

          <el-table-column property="name" label="Name" width="200">
            <template slot-scope="scope">
              <el-button type="primary" plain :disabled="scope.row.id === backgroundId" @click="setImage(scope.row.id)">Select</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-dialog>

  </div>
</template>

<script>

import { logout } from '../../utils/auth.js';
import { getImageRedirectUrl, getMenu, listImages, patchMenu } from '../../api/';

export default {
  name: 'BuildsFormComponent',
  props: ['menuId'],

  created () {
    window.menu = this.menu;
  },

  mounted () {

    Promise
      .all([
        getMenu(this.menuId).then((res) => res),
        listImages()
          .then((result) => {
            return result.data
              .map((image) => {
                const uri = 'https://vision-editor-data.ams3.digitaloceanspaces.com/images';
                return {
                  ...image,
                  url: `${uri}/${image.id}.${image.extension}`,
                };
              });
          }),
      ])
      .then(([menu, images]) => {
        this.menu = menu;
        this.backgroundId = menu.background_id;
        this.galleryItems = images;
      })
      .catch((err) => {
        if (err.response.status === 401) {
          logout();
          this.$router.push({ name: 'login' });
        }
      });
  },

  data() {
    return {
      menu: null,
      saving: false,
      backgroundId: null,
      backgroundOpacity: 0,
      galleryItems: [],
      dialogGalleryVisible: false,
    };
  },

  methods: {
    getImageRedirectUrl (id) {
      return getImageRedirectUrl(id);
    },
    saveContent () {
      patchMenu(this.menuId, { background_id: this.backgroundId })
        .then(() => {
          this.$message({
            message: 'Menu updated successfully.',
            type: 'success'
          });
        })
        .catch(() => {
          this.$message({
            message: 'Menu update failed.',
            type: 'error'
          });
        });
    },

    setImage (imageId) {
      this.backgroundId = imageId;
      this.dialogGalleryVisible = false;
    },

  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* h1, h2 {
  font-weight: normal;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
} */
</style>
