<template>
  <div>
    <h1>Content</h1>

    <h5>Content</h5>
    <!-- <template v-for="content in zzz">
      <div>
        <span>{{ content.language_text }} - {{ content.title }}</span>
        <el-button style="float: right; padding: 3px 0" type="text">Operation button</el-button>
      </div>
      <div>
        <el-input type="textarea" style="width: 100%;" v-model="content.text"></el-input>
      </div>
    </template> -->

    <h5>Background</h5>
    <div style="width: 300px; height: 200px;">

    </div>
  </div>
</template>

<script>
export default {
  name: 'OverviewFormComponent',
  data() {
    return {
      msg: 'Welcome to Your Vue.js App',
      zzz: [
        {
          language: 'English',
          language_text: 'English',
          title: 'A',
          text: `Culinary experience. Our guests are charmed by the taste of typical Hungarian and sumptuous International dishes. In case of any food allergy or intolerance please let our chefs know in advance who will stay at your disposal by preparing your favourites according to your taste.

Opening hours
Breakfast: 7:00 am – 11:00 am
Dinner: 5:30 pm – 9:30 pm`,
        },
        {
          language: 'Hungarian',
          language_text: 'Hungarian',
          title: 'A',
          text: '',
        },
        {
          language: 'German',
          language_text: 'German',
          title: 'A',
          text: '',
        },
        {
          language: 'Russian',
          language_text: 'Russian',
          title: 'A',
          text: '',
        },
        {
          language: 'French',
          language_text: 'French',
          title: 'A',
          text: '',
        },
        {
          language: 'Italian',
          language_text: 'Italian',
          title: 'A',
          text: '',
        },
      ],
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* h1, h2 {
  font-weight: normal;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
} */
</style>
