<template>
  <div>
    <h1>Summary</h1>

    <h2>{{ siteName }}</h2>
  </div>
</template>

<script>
export default {
  name: 'OverviewFormComponent',
  data() {
    return {
      msg: '',
    };
  },
  computed: {
    siteName () {
      return this.$store.state.sites[0].name;
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* h1, h2 {
  font-weight: normal;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
} */
</style>
