<template>
  <div>
    <h1>Menu</h1>

    <el-tree
      :data="menu"
      node-key="id"
      default-expand-all
      highlight-current
      :props="defaultProps"
      :expand-on-click-node="false">
      <span class="custom-tree-node" slot-scope="{ node, data }">
        <span>
          <font-awesome-icon :class="['menu-icon', `menu-icon-${data.type}`]" :icon="getIcon(data)" fixed-width size="sm" />
          <router-link class="link" v-if="data.type === 'content'" :to="{ name: 'content', params: { contentId: data.id } }">
            {{ data.title.en || data.title.hu }}
          </router-link>

          <router-link class="link" v-if="data.type === 'contentselector'" :to="{ name: 'menu', params: { menuId: data.id } }">
            {{ data.title.en || data.title.hu }}
          </router-link>

          <!-- television -->
          <!-- billing -->
          <!-- messages -->
          <!-- shop -->
          <!-- alarm -->
          <!-- applications -->
          <!-- welcome -->
          <span v-if="data.type !== 'content' && data.type !== 'contentselector'">
            {{ data.title.en || data.title.hu }}
          </span>

          <!-- <span style="font-size: 60%; margin-left: 20px;">{{ data.id }}</span> -->

          <el-popover
            v-if="data.background_id && galleryItems"
            placement="top-start"
            title=""
            width="200"
            trigger="hover">
            <img
              :src="galleryItems.find((img) => img.id === data.background_id).url"
              style="width: 100%;"
              decoding="async"
            />
            <i slot="reference" class="el-icon-picture"></i>
         </el-popover>


        </span>
        <!-- <el-tag size="mini">content</el-tag> -->
        <span>
          <!-- <el-button
            type="text"
            size="mini"
            @click="() => append(data)">
            Append
          </el-button>
          <el-button
            type="text"
            size="mini"
            @click="() => remove(node, data)">
            Delete
          </el-button> -->
        </span>
      </span>
    </el-tree>

  </div>
</template>

<script>

import { logout } from '../../utils/auth.js';
import { getMenus, listImages, getContents } from '../../api/';

export default {
  name: 'BuildsFormComponent',

  created () {

    window.menu = this.menu;

  },

  mounted () {
    getMenus()
      .then((response) => {

        const menus = response.data;

        return getContents()
          .then((response) => response.data)
          .then((contentResponses) => {
            const disabledMap = contentResponses
              .reduce((h, item) => {
                if (item && item.content && item.content.en && item.content.en.length > 10) {
                  // h[item.menu_id] = true;
                  return h;
                }
                return h;
              }, {});

            return menus
              .filter((m) => {
                if (disabledMap[m.id]) {
                  return false;
                }
                return true;
              });
          });

        // return menus;

      })
      .then((menus) => {

        function buildTree (input, list, parent) {
          const items = input
            .filter((menu) => menu.parent === parent);

          items.sort((a, b) => a.sequence - b.sequence);

          items.forEach((item) => {
            list.push(item);
            item.children = [];
            buildTree(input, item.children, item.id);
          });

          return list;
        }

        this.menu = buildTree(menus, [], null);

      })
      .catch((err) => {
        if (err.response.status === 401) {
          logout();
          this.$router.push({ name: 'login' });
        }
      });

    listImages()
      .then((result) => {
        return result.data
          .map((image) => {
            const uri = 'https://vision-editor-data.ams3.digitaloceanspaces.com/images';
            return {
              ...image,
              url: `${uri}/${image.id}.${image.extension}`,
            };
          });
      })
      .then((items) => {
        this.galleryItems = items;
      });
  },

  data() {
    return {
      menu: [],
      defaultProps: {
        children: 'children',
        label: 'label',
        disabled: function (data) { // (data, node)
          return data.id === 2;
        },
      },
      galleryItems: null,
    };
  },

  methods: {
    getIcon (menu) {
      switch (menu.type) {
        case 'television':
          return ['far', 'tv'];
        case 'billing':
          return ['far', 'file-invoice-dollar'];
        case 'messages':
          return ['far', 'envelope'];
        case 'shop':
          return ['far', 'shopping-bag'];
        case 'content':
          return ['far', 'paragraph'];
        case 'alarm':
          return ['far', 'alarm-clock'];
        case 'contentselector':
          return ['far', 'list-ul'];
        case 'applications':
          return ['far', 'play'];
        default:
          return ['far', 'star'];
      }
    },
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* h1, h2 {
  font-weight: normal;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
} */


.menu-icon-content {
  color: #409EFF;
}

.link {
  text-decoration: none;
  color: inherit;
}

.link:hover {
  text-decoration: underline;
}
</style>
