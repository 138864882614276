<template>
  <div>
    <h1>Devices</h1>

    <el-table
      :data="televisionList"
      stripe
      style="width: 100%"
      :default-sort="{ prop: 'room', order: 'ascending' }"
      >
      <el-table-column
        label=""
        width="100px"
        align="right">
        <!-- <template slot-scope="scope"> -->
        <template>


          <!-- <font-awesome-icon v-if="scope.row.softAp" :icon="['far', 'wifi']" fixed-width size="sm" /> -->

          <el-tooltip class="item" effect="dark" content="Offline" placement="top-start">
            <font-awesome-icon class="menu-icon status-offline" :icon="['far', 'power-off']" fixed-width size="sm" />
          </el-tooltip>

        </template>
      </el-table-column>
      <el-table-column
        prop="serial"
        label="Serial"
        sortable
        >
        <template slot-scope="scope">
          <span style="font-family: monospace, mono, Arial;">{{ scope.row.serial || '' }}</span>
        </template>

      </el-table-column>
      <el-table-column
        prop="type"
        label="Type"
        >
        <template slot-scope="scope">
          <!-- LG LX761H-ZA -->
          {{ scope.row.type_id || '' }}

        </template>

      </el-table-column>
      <el-table-column
        prop="room"
        label="Room"
        sortable
        >
      </el-table-column>
    </el-table>

  </div>
</template>

<script>

import { listTelevisions } from '../../api/';

export default {
  name: 'ChannelsFormComponent',
  data() {
    return {
      editing: 0,
      televisionList: [],
    };
  },

  mounted () {
    listTelevisions()
      .then((response) => {

        this.televisionList = response.data
          .map((item) => {

            return {
              ...item,

              // softAp: apConfig[item.serial] || false,
            };

          });
      });
  },

  methods: {
    toggleEditor (product) {
      if (!product) {
        this.editing = null;
        return;
      }

      this.editing = product.id;
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* h1, h2 {
  font-weight: normal;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
} */

.status-offline {
  color: #cccccc;
}
</style>
